import { t } from "i18next";
import config from "../../../config";
import images, { imagesAlts } from "../../../resources/images";
import classes from "./AppDownload.module.css";

const AppDownload = ({ fromAboutUs = false }) => {
  const isIL = config.currentCountry === 'IL'
  return (
    <div className={fromAboutUs ? classes.mainContainerAboutUs : classes.mainContainer}>
      <div className={fromAboutUs ? classes.contentWrapperAboutUs : classes.contentWrapper}>
        <img
          className={fromAboutUs ? classes.imageContainerAboutUs : classes.imageContainer}
          src={images.appComponentPhone}
          alt={imagesAlts.appComponentPhone}
        />
        <div className={fromAboutUs ? classes.textContainerAboutUs : classes.textContainer}>
          <h5 className={ classes.title }>{ isIL ? t("download_our_app") : t("app_promotion") }</h5>
          <h6 className={ classes.description }>{ isIL ? t("about_app_with_benefits") : t("about_app") }</h6>

          <div className={classes.buttonsContainer}>
            <a
              className={classes.downloadButton}
              target="_blank"
              href={ "https://apps.apple.com/il/app/vegan-friendly/id1446617122" } rel="noreferrer"
            >
              <img src={images.downloadAppStore} alt={imagesAlts.downloadAppStore} />
            </a>
            <a
              className={classes.downloadButton}
              target="_blank"
              href={
                "https://play.google.com/store/apps/details?id=com.veganfriendly.mobileapp&hl=en&gl=US&pli=1"
              } rel="noreferrer"
            >
              <img src={images.downloadGooglePlay} alt={imagesAlts.downloadGooglePlay} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDownload;
