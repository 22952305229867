import { getAnalytics, logEvent } from "firebase/analytics";
import mixpanel from "mixpanel-browser";
import { isMobile } from "react-device-detect";
import config from "./config";

export const getUserProperties = (id) => {
  return {
    userId: id,
    login_level: "anonymous",
    product: isMobile ? "mobile" : "web",
    websiteDomain: config.currentCountry,
    test: config.isTest,
    version: config.version,
    referrer: document.referrer ? document.referrer : "unknown",
  };
};

export const getCurrentPage = () => {
  try {
    const path = window.location.pathname;
    const page = path.split("/")[1];
    return page ? page : "home";
  } catch (ex) {}
  return "";
};

export const logEvents = (eventName, props = {}) => {
  props.websiteState = config.currentState;
  props.page = getCurrentPage();
  //const analytics = getAnalytics();
  mixpanel.track(eventName, props);
  //logEvent(analytics, eventName, props);
};
