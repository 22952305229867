import { t } from "i18next";
import logo from "../../../assets/images/logo.png";
import icons, { iconsAlts } from "../../../resources/icons";
import { DropDown } from "../../Common";
import classes from "../VfNavBar.module.css";

const NavBarMobile = ({
  homepageHandler,
  getPrimaryButtonByCountry,
  getInvolvedContent,
  getEntitiesContent,
  navigateToAboutUs,
  navigatedToTrademark,
  setSearchModalOpen,
  getInvolvedStyle,
  logClickEvent,
}) => {

  return (
    <>
      <div className={classes.mobileContainer}>
        <div className={classes.leftContainer}>
        <DropDown
            className={getInvolvedStyle()}
            header={t("categories")}
            buttonComponent={<img className={classes.hamburgerMenu} src={icons.hamburgerMenu} />}
            innerComponent={getInvolvedContent}
          />
          <button className={classes.searchContainer} onClick={() => setSearchModalOpen(true)}>
            <img src={icons.searchButtonIcon} alt={iconsAlts.searchButtonIcon} />
          </button>


        </div>
        <div className={classes.rightContainer}>
          {getPrimaryButtonByCountry()}
          <>
            <img
              src={logo}
              className={classes.veganLogo}
              onClick={homepageHandler}
              alt={iconsAlts.veganLogo}
            />
          </>
        </div>
      </div>
    </>
  );
};

export default NavBarMobile;
