import { BENEFITS, BLOGS, BRANCHES, BRANDS, EMPLOYERS, RESTAURANTS } from "../resources/config";

export const getCurrentCountry = () => {
  return process.env.REACT_APP_COUNTRY;
};

export const getCurrentLocale = () => {
  if (getCurrentCountry() === "IL") {
    return "he";
  } else {
    return "en";
  }
};

//returns true only if specific entity is relevant to the current country
export const checkEntityByCountry = (entity) => {
  const currentCountry = getCurrentCountry();
  if (currentCountry === "US") {
    return entity === RESTAURANTS || entity === BRANCHES || entity === BRANDS; 
  } else if (currentCountry === "GB") {
    return entity !== BLOGS && entity !== EMPLOYERS && entity !== BENEFITS
  } else {
    return true;
  }
};
