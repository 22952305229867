import { t } from "i18next";
import { useEffect } from "react";
import { AboutLabel, AboutUsHeader, AboutUsVideo, AboutVa } from "../../components/AboutUs";
import AppDownload from "../../components/Media/AppDownload/AppDownload";
import config from "../../config";
import { logEvents } from "../../eventsManager";

const Trademark = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        logEvents("screen_about_us_open");
    }, []);

    const isUS = config.currentCountry === "US";

    return (
        <div>
            <>
                <AboutLabel />
            </>
            { !isUS && <AppDownload fromAboutUs={ true } /> }
        </div>
    );
};

export default Trademark;
