import { t } from "i18next";
import config from "../../../config";
import { BRANDS, RESTAURANTS } from "../../../resources/config";
import icons from "../../../resources/icons";
import images, { imagesAlts } from "../../../resources/images";
import * as links from "../../../utils/links";
import { URLS } from "../../../utils/links";
import classesUK from "./AboutLabelUK.module.css";
import classesUS from "./AboutLabelUS.module.css";

const AboutLabel = () => {
  const isGB = config.currentCountry === "GB";
  const isUS = config.currentCountry === "US";
  const classes = isUS ? classesUK : classesUK;
  const getCertifiedLink = 'https://forms.monday.com/forms/801a7ebde793aae4622fcdc7f95b0f0f?r=use1'
  const getRestaurantLabelImageByCountry = () => {
    const currentCountry = config.currentCountry;
    switch (currentCountry) {
      case "US":
        return images.aboutLabelRestaurantUS;
      case "GB":
        return images.aboutLabelRestaurantUK;
      case "IL":
        return images.aboutLabelRestaurantIL;
    }
  };

  const getProductLabelImageByCountry = () => {
    const currentCountry = config.currentCountry;
    switch (currentCountry) {
      case "US":
        return images.aboutLabelProductUS;
      case "GB":
        return images.aboutLabelProductUK;
      case "IL":
        return images.aboutLabelProductIL;
    }
  };

  return (
    <div>
      <section className={classes.ourLabel}>
        <h3 className={classes.title}>{t("our_label")}</h3>
        <p className={classes.description}>{t("our_label_description")}</p>
      </section>
      {
        <section className={classes.productLabel}>
          <img
            className={isUS ? classes.imageUSA : classes.image}
            src={getProductLabelImageByCountry()}
            alt={imagesAlts.aboutLabelProduct}
          />

          <div className={classes.productContent}>
            <h4 className={classes.title}>{t("our_label_products")}</h4>
            <p className={classes.description}>{t("our_label_products_description")}</p>
            <a className={ classes.link } target={ isGB ? "_blank" : "_self" } href={ isGB ? getCertifiedLink : links.linkToSearch({ entityType: BRANDS }) } rel="noreferrer">
              {t("our_label_products_link")}
            </a>
            <img
              className={classes.greenArrowProduct}
              src={images.aboutGreenArrow}
              alt={imagesAlts.aboutGreenArrow}
            />
          </div>
        </section>
      }
      <section className={classes.restaurantsLabel}>
        <img
          className={classes.image}
          src={getRestaurantLabelImageByCountry()}
          alt={imagesAlts.aboutLabelRestaurant}
        />

        <div className={classes.restaurantsContent}>
          <h4 className={classes.title}>{t("our_label_restaurants")}</h4>
          <p className={classes.description}>{t("our_label_restaurants_description")}</p>
          <a className={ classes.link } target={ isGB ? "_blank" : "_self" } href={ isGB ? getCertifiedLink : links.linkToSearch({ entityType: RESTAURANTS }) } rel="noreferrer">
            {t("our_label_restaurants_link")}
          </a>
          <img
            className={classes.greenArrowRestaurant}
            src={images.aboutGreenArrow}
            alt={imagesAlts.aboutGreenArrow}
          />
        </div>
      </section>
      <section className={classes.businessesLabel}>
        <div className={classes.businessesContent}>
          <h4 className={classes.title}>{t("our_label_businesses")}</h4>
          <p className={classes.description}>{t("our_label_businesses_description")}</p>
          <a className={ classes.link } target="_blank" href={ URLS.get_certified } rel="noreferrer">
            {t("our_label_businesses_link")}
          </a>
          <img
            className={classes.greenArrowLabel}
            src={images.aboutGreenArrow}
            alt={imagesAlts.aboutGreenArrow}
          />
        </div>
        <img
          className={classes.image}
          src={images.aboutLabelBusinessesUS}
          alt={imagesAlts.aboutLabelBusinesses}
        />
      </section>
    </div>
  );
};

export default AboutLabel;
