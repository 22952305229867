import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import config from "../../config";
import { logEvents } from "../../eventsManager";
import { ContactUsModal, NewsletterModal, NewsModal, SearchModal } from "../../modals";
import ContactUsSuccessModal from "../../modals/ContactUs/ContactUsSuccessModal/ContactUsSuccessModal";
import { RESTAURANTS } from "../../resources/config";
import icons, { iconsAlts } from "../../resources/icons";
import * as links from "../../utils/links";
import { getCurrentLocale } from "../../utils/utilFunctions";
import { DropDown, PrimaryButton } from "../Common";
import EntitiesMenu from "./EntitiesMenu.js/EntitiesMenu";
import GetInvolvedMenu from "./GetInvolvedMenu/GetInvolvedMenu";
import NavBarMobile from "./NavBarMobile/NavBarMobile";
import NavBarWeb from "./NavBarWeb/NavBarWeb";
import classes from "./VfNavBar.module.css";

const VfNavBar = () => {
  const navigate = useNavigate();

  const [isNewsletterPopped, setNewsletterPopped] = useState(false);
  const [isNewsletterModalVisible, setNewsletterModalVisible] = useState(false);
  const [contactUsOption, setContactUsOption] = useState(null);
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [isContactUsSuccessOpen, setContactUsSuccessOpen] = useState(false);
  const [contactUsRequestCode, setContactUsRequestCode] = useState(null);
  const [isSearchModalOpen, setSearchModalOpen] = useState(false);
  const [isNewsModalOpen, setNewsModalOpen] = React.useState(false);

  useEffect(() => {
    startNewsletterTimer();
  }, []);

  const logClickEvent = (buttonName) => {
    const params = { button_name: buttonName };
    logEvents("header_and_footer_click", params);
  };

  const navigateToVeganActive = () => {
    logClickEvent("vegan_active");
    links.linkToExternalPage(links.URLS.vegan_active_homepage);
  };

  const onCertifiedClick = () => {
    if (config.currentCountry === "US") {
      if (!isNewsModalOpen) {
        logClickEvent("get_certified");
        setNewsModalOpen(true);
      }
    } else {
      links.linkToExternalPage(links.URLS.get_certified);
    }
  };

  const navigateToAboutUs = () => {
    logClickEvent("about_us");
    navigate(links.linkToAbout());
  };
  const navigatedToTrademark = () => {
    logClickEvent("trademark");
    navigate(links.linkToTrademark());
  }
  const navigateToRestaurants = () => {
    logClickEvent("restaurants");
    navigate(links.linkToSearch({ entityType: RESTAURANTS }));
  };

  const startNewsletterTimer = () => {
    const country = config.currentCountry;
    if (country != "US") {
      const subscribed = localStorage.getItem("subscribed_to_newsletter");
      if (!isNewsletterPopped && !subscribed) {
        setNewsletterPopped(true);
        setTimeout(() => {
          setNewsletterModalVisible(true);
        }, 600000);
      }
    }
  };

  const contactUsHandler = (optionNumber) => {
    logClickEvent("contact_us");
    if (optionNumber) {
      setContactUsOption(optionNumber);
    } else {
      setContactModalOpen((isOpen) => !isOpen);
    }
  };

  const getInvolvedContent = (onClose) => {
    return (
      <GetInvolvedMenu
        onClose={onClose}
        contactUsHandler={contactUsHandler}
        newsletterHandler={() => {
          setNewsletterModalVisible(true);
          setNewsletterPopped(true);
        }}
        getCertifiedHandler={onCertifiedClick}
      />
    );
  };

  const getEntitiesContent = (onClose) => {
    return <EntitiesMenu onClose={onClose} />;
  };

  const homepageHandler = () => {
    logClickEvent("home");
    navigate(links.linkToMainPage());
    window.scrollTo(0, 0);
  };

  const getInvolvedStyle = () => {
    const locale = getCurrentLocale();
    return locale === "he" ? classes.getInvolvedDropDownHE : classes.getInvolvedDropDownEN;
  };

  const getPrimaryButtonByCountry = () => {
    const currentCountry = config.currentCountry;
    switch (currentCountry) {
      case "IL":
        return (
          <PrimaryButton
            title={t("join_vegan_active")}
            className={classes.veganActiveButton}
            onClick={navigateToVeganActive}
            isSlim={true}
          />
        );
      case "US":
        return (
          <PrimaryButton
            title={t("get_certified")}
            className={classes.veganActiveButton}
            onClick={onCertifiedClick}
            isSlim={true}
          />
        );
      case "GB":
        return (
          <PrimaryButton
            title={t("get_certified")}
            className={classes.veganActiveButton}
            onClick={onCertifiedClick}
            isSlim={true}
          />
        );
    }
  };

  return (
    <>
      <NavBarMobile
        homepageHandler={homepageHandler}
        getPrimaryButtonByCountry={getPrimaryButtonByCountry}
        getInvolvedContent={getInvolvedContent}
        getEntitiesContent={getEntitiesContent}
        navigateToAboutUs={navigateToAboutUs}
        navigatedToTrademark={ navigatedToTrademark }
        setSearchModalOpen={setSearchModalOpen}
        getInvolvedStyle={getInvolvedStyle}
        logClickEvent={logClickEvent}
      />
      <NavBarWeb
        homepageHandler={homepageHandler}
        getPrimaryButtonByCountry={getPrimaryButtonByCountry}
        getInvolvedContent={getInvolvedContent}
        getEntitiesContent={getEntitiesContent}
        navigateToAboutUs={navigateToAboutUs}
        navigatedToTrademark={ navigatedToTrademark }
        setSearchModalOpen={setSearchModalOpen}
        getInvolvedStyle={getInvolvedStyle}
        logClickEvent={logClickEvent}
      />
      <NewsletterModal
        show={isNewsletterModalVisible}
        onHide={() => setNewsletterModalVisible(false)}
      />
      <ContactUsModal
        show={isContactModalOpen || contactUsOption != null}
        optionNumber={contactUsOption}
        onHide={() => {
          setContactModalOpen(false);
          setContactUsOption(null);
        }}
        onSuccess={(code) => {
          setContactUsSuccessOpen(true);
          setContactUsRequestCode(code);
        }}
      />
      <ContactUsSuccessModal
        show={isContactUsSuccessOpen}
        onHide={() => setContactUsSuccessOpen(false)}
        caseNumber={contactUsRequestCode}
      />
      <SearchModal show={isSearchModalOpen} onHide={() => setSearchModalOpen(false)} />
      <NewsModal show={isNewsModalOpen} onHide={() => setNewsModalOpen(false)} newsId={1} />
    </>
  );
};

export default VfNavBar;
