import React from "react";

import EntityButtons from "../../components/Entities/EntityTypeButtons/EntityButtons";

import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BusinessBanner from "../../components/Banners/BusinessBanner/BusinessBanner";
import { EntityCarousel } from "../../components/Entities/EntityContainers";
import CompaniesSlider from "../../components/HomePage/CompaniesSlider/CompaniesSlider";
import Hero from "../../components/HomePage/Hero/Hero";
import AppDownload from "../../components/Media/AppDownload/AppDownload";
import InstagramReferral from "../../components/Media/InstagramReferral/InstagramReferral";
import config from "../../config";
import { logEvents } from "../../eventsManager";
import { LocationSearchModal, NewsletterModal } from "../../modals";
import { BENEFITS, BLOGS, BRANCHES, BRANDS, EMPLOYERS, RECIPES, RESTAURANTS } from "../../resources/config";
import {
  benefitUtils,
  blogUtils,
  businessUtils,
  companyUtils,
  employerUtils,
  recipeUtils,
  restaurantUtils
} from "../../utils/entityUtils";
import * as navigationLinks from "../../utils/links";
import SEO from "../../utils/SEO";
import classes from "./Home.module.css";

const Home = () => {
  const navigation = useNavigate();
  const userLocation = useSelector((state) => state.user.userLocation);
  const isEntitiesLoading = useSelector((state) => state.entities.isLoading);
  const entityCategories = useSelector((state) => state.entities.entityCategories);
  const entityTags = useSelector((state) => state.entities.entityTags);
  const homePageEntities = useSelector((state) => state.entities.homePageEntities);

  useEffect(() => {
    window.scrollTo(0, 0);
    logEvents("homepage_loaded");
  }, []);

  useEffect(() => {
    if (
      homePageEntities &&
      entityCategories &&
      entityTags &&
      userLocation &&
      userLocation.coords &&
      !isEntitiesLoading
    ) {
      filterHomePageEntities();
    }
  }, [homePageEntities, entityCategories, entityTags, userLocation, isEntitiesLoading]);

  const [isLoading, setLoading] = useState(true);
  const [topEntities, setTopEntities] = useState([{}, {}]);
  const [bottomEntities, setBottomEntities] = useState([{}, {}]);

  useEffect(() => {
    if (isEntitiesLoading) {
      setLoading(true);
    }
  }, [isEntitiesLoading]);

  const filterHomePageEntities = () => {
    const topList = [];
    const bottomList = [];
    const entities = JSON.parse(JSON.stringify(homePageEntities));
    entities.map((entity) => {
      if (
        entity.entity_name === "allBenefits" ||
        entity.entity_name === "restaurantBenefits" ||
        entity.entity_name === "businessBenefits"
      ) {
        if (entity.entity_name === "restaurantBenefits") entity.benefitType = "restaurantBenefit";

        if (entity.entity_name === "businessBenefits") entity.benefitType = "businessBenefit";

        entity.entity_name = BENEFITS;
      }

      if (entity.entity_list && entity.entity_list.length) {
        switch (entity.entity_name) {
          case RESTAURANTS:
            entity.entity_list = entity.entity_list.map((restaurant) =>
              restaurantUtils.prepareRestaurant(
                restaurant,
                entityCategories.restaurants,
                entityTags,
                userLocation.coords
              )
            );

            break;
          case BRANCHES:
            entity.entity_list = businessUtils.prepareBusinessBranchesList(entity.entity_list);
            entity.entity_list = entity.entity_list.map((business) =>
              businessUtils.prepareBusinessBranch(
                business,
                entityCategories.businessesSubs,
                entityTags,
                userLocation.coords
              )
            );
            break;
          case RECIPES:
            entity.entity_list = entity.entity_list.map((recipe) =>
              recipeUtils.prepareRecipe(recipe, entityCategories.recipes, entityTags, userLocation)
            );
            break;
          case BENEFITS:
            entity.entity_list = entity.entity_list.map((benefit) =>
              benefitUtils.prepareBenefit(benefit, userLocation.coords)
            );
            break;
          case BRANDS:
            entity.entity_list = entity.entity_list.map((company) =>
              companyUtils.prepareCompany(company, entityCategories.companiesSubs)
            );
            break;
          case BLOGS:
            entity.entity_list = entity.entity_list.map((blog) =>
              blogUtils.prepareBlog(blog, entityCategories.blogs)

            );
          break;
          case EMPLOYERS:
            entity.entity_list = entity.entity_list.map((employer) =>
              employerUtils.prepareEmployer(employer, entityCategories.employers)

            );
          break;

        }

        if (entity.sort_type === "distance") {
          entity.entity_list = entity.entity_list.filter((entity) => entity.distance != null);
          entity.entity_list = entity.entity_list
            .sort((a, b) => {
              return (b.distance != null) - (a.distance != null) || a.distance - b.distance;
            })
            .slice(0, 11);
        }

        if (entity.is_top) {
          topList.push(entity);
        } else {
          bottomList.push(entity);
        }
      }
    });
    const sortFunc = (first, second) => first.appearance_order - second.appearance_order;

    topList.sort(sortFunc);
    setTopEntities(topList);

    bottomList.sort(sortFunc);
    setBottomEntities(bottomList);
    setLoading(false);
  };

  const locationChosenHandler = (address) => {
    if (!address) {
      navigation(navigationLinks.linkToSearch(), true);
    }
  };

  const isUS = config.currentCountry === "US";
  const isUK = config.currentCountry === "GB";
  const isIL = config.currentCountry === "IL";

  const [isLocationModalVisible, setLocationModalVisible] = useState(false);
  const openLocationHandler = () => {
    setLocationModalVisible(true);
  };

  return [
    <div className={classes.mainContainer} key="home">
      <Hero onLocationClick={openLocationHandler} />
      <EntityButtons entityCategories={ entityCategories } isUK={ isUK } />
      <div className={classes.paddedContent}>
        <BusinessBanner />
        {topEntities &&
          topEntities.map((data, index) => (
            <EntityCarousel
              key={data.id ? data.id : index}
              title={data.title}
              entities={data.entity_list}
              entityType={data.entity_name}
              benefitType={data.benefitType}
              isLoading={isLoading}
            />
          ))}
      </div>
       <AppDownload />
      <div className={classes.paddedContent}>
        {bottomEntities &&
          bottomEntities.map((data, index) => (
            <EntityCarousel
              key={data.id ? data.id : index}
              title={data.title}
              entities={data.entity_list}
              entityType={data.entity_name}
              benefitType={data.benefitType}
              isLoading={isLoading}
            />
          ))}
      </div>
      {false && isIL && <CompaniesSlider />}
      <div className={classes.paddedContent}>{!isLoading && <InstagramReferral />}</div>
      <LocationSearchModal
        show={isLocationModalVisible}
        onHide={() => setLocationModalVisible(false)}
        onLocationChosen={locationChosenHandler}
      />
    </div>,
  ];
};

export default Home;
