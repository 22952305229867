import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router";
import config from "../../../config";
import { logEvents } from "../../../eventsManager";
import { NewsModal } from "../../../modals";
import images, { imagesAlts } from "../../../resources/images";
import * as links from "../../../utils/links";
import classes from "./BusinessBanner.module.css";

const BusinessBanner = ({ isGreen }) => {
  const [isNewsModalOpen, setNewsModalOpen] = React.useState(false);
  const isGB = process.env.REACT_APP_COUNTRY === 'GB'
  const navigate = useNavigate();

  const onBannerClick = () => {
    logEvents("ad_click", {
      banner_name: "get_certified",
      banner_location: isGreen ? "footer" : "home",
    });
    if (isGB) {
      console.log('linking to trademark')
      navigate(links.linkToTrademark());
      return

    }

    if (config.currentCountry === "US") {
      if (!isNewsModalOpen) {
        setNewsModalOpen(true);
      }
    } else {
      links.linkToExternalPage(links.URLS.get_certified);
    }
  };

  const onCertifiedClick = () => {
    if (config.currentCountry === "US") {
      if (!isNewsModalOpen) {
        setNewsModalOpen(true);
      }
    } else {
      links.linkToExternalPage(links.URLS.get_certified);
    }
  };

  return (
    <button
      type="button"
      className={isGreen ? classes.mainContainerGreen : classes.mainContainer}
      onClick={onBannerClick}
    >
      <img
        className={isGreen ? classes.iconGreen : classes.icon}
        src={images.bannerSuitcase}
        alt={imagesAlts.bannerSuitcase}
      />
      <div className={isGreen ? classes.contentContainerGreen : classes.contentContainer}>
        <div className={classes.row}>
          <h4 className={isGreen ? classes.titleGreen : classes.title}>
            { isGB ? t("business_banner_title_uk") : t("business_banner_title") }
          </h4>
        </div>
        <h4 className={ isGreen ? classes.subtitleGreen : classes.subtitle }>{ isGB ? t("business_banner_subtitle_uk") : t("business_banner_subtitle") }</h4>

      </div>
      <NewsModal show={isNewsModalOpen} onHide={() => setNewsModalOpen(false)} newsId={1} />
    </button>
  );
};

export default BusinessBanner;
